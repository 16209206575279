import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function Support() {
    return(
        <>
            <Navbar/>
            <div className="w3-row-padding w3-padding-64 w3-container">
                <div className="w3-content">
                    For assistance with your product, reach out to our helpful support team at
                    <a href={"tel:+91-9444083187"}>+91-9444083187</a> or send us an email at <a href={"mailto:patanvenky@gmail.com"}>patanvenky@gmail.com</a>
                </div>
            </div>
            <Footer/>
        </>
    );
}
